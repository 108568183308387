<template>
    <svg
        width="31"
        height="24"
        viewBox="0 0 31 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M29.749 11.8271L1.78634 11.8271M12.2723 22.5202L1.78634 11.8271L12.2723 1.13393"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
