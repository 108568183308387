<template>
    <svg
        width="131"
        height="131"
        viewBox="0 0 131 131"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M65.3136 2.44873L65.3136 129.116"
            stroke="#FFEFE1"
            stroke-width="3"
            stroke-linecap="round"
        />
        <path
            d="M128.648 65.7847L1.97998 65.7847"
            stroke="#FFEFE1"
            stroke-width="3"
            stroke-linecap="round"
        />
    </svg>
</template>
